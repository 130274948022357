
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>






    <b-tabs pills card vertical>
      <b-tab title="Individual Redemptions" active>
        <div class="row" style="margin-bottom: 20px;">

          <div class="col-md-2">
            <div>
              By Outlet:
            </div>
            <select class="form-control" v-model="outlet" >
              <option v-if="!roles.includes(role)" value="" > All </option>
              <option :value="shop" v-for="shop in outlets"> {{ shop.name }}</option>
            </select>
          </div>
          <div class="col-md-5">
            <div>
              By date:
            </div>
            <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" @click="getVoucherRedemptionsData">Filter</button>
          </div>
          <div class="col-md-5">
            <b-button class="btn btn-success float-right" v-b-modal.redeem-voucher>Redeem Voucher</b-button>
          </div>
        </div>
        <br/>
        <b-table striped hover :items="vouchers" :fields="columns">

          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(customer)="data">
            <span v-if="data.item.customer">{{ data.item.customer.name }}</span>
            <span v-if="data.item.voucherCode"> - {{ data.item.voucherCode }} </span>
          </template>

          <template v-slot:cell(outlet)="data">
            <span v-if="data.item.outlet">{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(redeemedBy)="data">
            <span v-if="data.item.redeemedBy">{{ data.item.redeemedBy.name }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

        </b-table>
        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)" class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="Corporate Redemptions">
        <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
          <div class="col-md-2">
            <label><strong>By Outlet:</strong></label> <br/>
            <select class="form-control" v-model="outlet" >
              <option :value="shop.id" v-for="shop in outlets"> {{ shop.name }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <label><strong>By Institution:</strong></label> <br/>
            <select class="form-control" v-model="institutionId" >
              <option value="0" > All </option>
              <option :value="institution.id" v-for="institution in institutions"> {{ institution.nameOfInstitution }}</option>
            </select>
          </div>
          <div class="col-md-7">
            <div class="row" style="margin-bottom: 20px;" >
              <div class="col-md-8">
                <label><strong>Filter by date:</strong></label> <br/>
                <div class="input-daterange-datepicker input-group" >
                  <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker>

                  <div class="input-group-append">
                    <span class="input-group-text bg-info b-0 text-white">TO</span>
                  </div>
                  <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker>
                </div>
              </div>

              <div class="col-md-4">
                <button style="margin-top:30px;" type="button" class="btn btn-danger" @click="getCorporateVoucherRedemptionsData()"><i class="fa fa-search"></i> Filter </button>


              </div>
            </div>
          </div>
        </div>
        <br/>
        <b-table striped hover :items="corporateVoucherRedemptions" :fields="corporateVoucherRedemptionColumns">
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(institution)="data">
            <span v-if="data.item.institution">{{ data.item.institution.nameOfInstitution }}</span>
            <span v-if="data.item.voucherCode"> - {{ data.item.voucherCode }} </span>
          </template>

          <template v-slot:cell(outlet)="data">
            <span v-if="data.item.outlet">{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(redeemedBy)="data">
            <span v-if="data.item.redeemedBy">{{ data.item.redeemedBy.name }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

        </b-table>
        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="paginationCorporateVoucherRedemptions.current_page > 1" class="page-item"><a @click.prevent="changeCorporateVoucherRedemptionPage(paginationCorporateVoucherRedemptions.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumberCorporateVoucherRedemption"
                    v-bind:class="[ page == isActivedCorporateVoucherRedemption ? 'active' : '']" class="page-item"><a @click.prevent="changeCorporateVoucherRedemptionPage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="paginationCorporateVoucherRedemptions.current_page < paginationCorporateVoucherRedemptions.last_page"  class="page-item"><a  @click.prevent="changeCorporateVoucherRedemptionPage(paginationCorporateVoucherRedemptions.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="Parking Vouchers">
        <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
          <div class="col-md-2">
            <label><strong>Filter by status:</strong></label> <br/>
            <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
              <select class="form-control" v-model="transaction_status" @change="getParkingVouchersData">
                <option value="all"> -- Select status --</option>
                <option value="PENDING"> Pending </option>
                <option value="EXPIRED"> Expired </option>
                <option value="ACTIVE"> Active </option>
              </select>
            </div>
          </div>
          <div class="col-md-7">
            <div class="row" style="margin-bottom: 20px;" >
              <div class="col-md-8">
                <label><strong>Filter by date:</strong></label> <br/>
                <div class="input-daterange-datepicker input-group" >
                  <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker>

                  <div class="input-group-append">
                    <span class="input-group-text bg-info b-0 text-white">TO</span>
                  </div>
                  <date-picker @input="getParkingVouchersData"   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker>
                </div>

              </div>

              <div class="col-md-4">

              </div>
            </div>
          </div>
          <div class="col-md-3 mt-2">
            <a v-if="canGenerateParkingVoucher" href="/apps/generateParkingVoucher" class="btn btn-success float-right mr-3 ml-3" >Generate Voucher</a>
          </div>
        </div>

        <br/>
        <b-table striped hover :items="parking_vouchers" :fields="parking_voucher_columns">
          <template v-slot:cell(transactionId)="data">
            <span v-if="data.item.transactionId"> {{ data.item.transactionId }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>
          <template v-slot:cell(balance)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

          <template v-slot:cell(parkingRedemptionStatus)="data">
            <span class="badge badge-success" v-if="data.item.parkingRedemptionStatus == 'REDEEMED'">Redeemed</span>
            <span class="badge badge-warning" v-else>Pending</span>
          </template>

          <template v-slot:cell(giftStatus)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">{{ data.value }}</span>
            <span class="badge badge-warning"  v-else-if="data.value == 'PENDING' || data.value == 'PARTIALLY_DEPLETED'" >{{ data.value.replace("-", " ") }}</span>
            <span class="badge badge-danger"  v-else-if="data.value == 'EXPIRED' || data.value == 'DEPLETED'" >{{ data.value }}</span>
          </template>

          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="options">
              <b-dropdown-item  @click="openVoucherExpiryModalModal(data.item, 'Individual')"> Update Voucher Expiry </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">


            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="paginationParkingVouchers.current_page > 1" class="page-item"><a @click.prevent="changePageParkingVouchers(paginationParkingVouchers.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumberParkingVouchers"
                    v-bind:class="[ page == isActivedParkingVouchers ? 'active' : '']" class="page-item"><a @click.prevent="changePageParkingVouchers(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="paginationParkingVouchers.current_page < paginationParkingVouchers.last_page"  class="page-item"><a  @click.prevent="changePageParkingVouchers(paginationParkingVouchers.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal id="redeem-voucher" title="Redeem Voucher" hide-footer   centered>
      <div class="mt-4 mb-4">
        <form-wizard ref="voucherDetails" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#" v-on:submit.prevent="validateBeforeSubmitRedemption" @on-complete="validateBeforeSubmitRedemption" @onNextStep="nextStep" @onPreviousStep="previousStep">
          <tab-content title="Step 1: Phone Number" :before-change="submitVoucherCodeVerification"
                       icon="ti-user" :selected="true" >
            <div class="form-group ">
              <label :class="{ 'text-danger': $v.redemption.redemptionCode.$error }">Enter voucher code:</label> <br/>
              <div >
                <input v-model.trim="$v.redemption.redemptionCode.$model" class="form-control" type="text" />
                <div class="text-danger" v-if="!$v.redemption.redemptionCode.required && $v.redemption.redemptionCode.$dirty">Voucher code is required</div>
              </div>
            </div>
          </tab-content>
          <tab-content title="Step 2: Verification Code" :before-change="verifyVcode"
                       icon="ti-user" >
            <div class="mt-5 mb-5">
              <div class="d-flex justify-content-center">
                <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
              </div>
            </div>
          </tab-content>
          <tab-content title="Step 3: Amount"
                       icon="ti-user" >
            <div class="form-group ">
              <label :class="{ 'text-danger': $v.redemption.amount.$error }">Enter Amount</label> <br/>
              <div >
                <input v-model.trim="$v.redemption.amount.$model" class="form-control" type="text" />
                <div class="text-danger" v-if="!$v.redemption.amount.required && $v.redemption.amount.$dirty">Amount is required</div>
              </div>
            </div>
          </tab-content>
        </form-wizard>

      </div>
    </b-modal>
    <b-modal id="update-voucher-expiry" title="Update Voucher Expiry" @cancel="true" @ok="submitVoucherExpiry"   centered>
      <form>
        <div class="form-group">
          <label>Expiry Date:</label> <br/>
          <date-picker   :lang="lang" v-model="voucherExpiry.expiryDate" valueType="format" :first-day-of-week="1"></date-picker>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'
import CodeInput from "vue-verification-code-input";
import { required,requiredIf} from 'vuelidate/lib/validators'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  data() {
    return {
      roles:["Merchant", "Cashier"],
      canGenerateParkingVoucher: false,
      voucherExpiry:{
        voucherId:"",
        expiryDate:"",
        voucherType:""
      },
      disabled:false,
      isLoading: false,
      fullPage: true,
      merchantId:"",
      transaction_status:"all",
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      paginationCorporateVoucherRedemptions:{
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      paginationParkingVouchers: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      columns:["no","transactionDate","customer","amount","redeemedBy","status","netsuiteVoucherPost"],
      corporateVoucherRedemptionColumns:["no","transactionDate","institution","amount","redeemedBy","status","outlet"],
      vouchers:[],
      invoice:{id:0},
      start_date:"all",
      end_date:"all",
      outlet:null,
      outlets:[],
      redemption_invoices:[],
      transactionType:"",
      redemption:{
        redemptionCode:"",
        amount:"",
        redeemedBy:""
      },
      verificationCode:"",
      customerVerificationCode:"",
      voucherCodeVerified: false,
      institutionId:0,
      institutions:[],
      corporateVoucherRedemptions:[],
      parking_vouchers:[],
      parking_voucher_columns:[{
        key: 'created',
        label: 'Date',
        sortable: false
      },
        ,"recipient",
        {
          key: 'parkingDuration',
          label: 'Duration (Minutes)',
          sortable: false
        },
        "parkingDuration",
        "voucherCode",
        "parkingRedemptionStatus",
        {
          key: 'giftMessage',
          label: 'Purpose',
          sortable: false
        }
        ,{
          key: 'giftStatus',
          label: 'Status',
          sortable: false
        },"expiryDate","Options"],
    };
  },
  validations: {
    redemption:{
      redemptionCode:{
        required
      },
      amount:{
        required: requiredIf(function () {
          return this.voucherCodeVerified
        })
      }
    },
  },
  computed:{
    isActivedParkingVouchers: function () {
      return this.paginationParkingVouchers.current_page + 1;
    },
    pagesNumberParkingVouchers: function () {
      if (!this.paginationParkingVouchers.to) {
        return [];
      }
      var from = this.paginationParkingVouchers.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.paginationParkingVouchers.last_page) {
        to = this.paginationParkingVouchers.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  isActivedCorporateVoucherRedemption: function () {
    return this.paginationCorporateVoucherRedemptions.current_page + 1;
  },
  pagesNumberCorporateVoucherRedemption: function () {
    if (!this.paginationCorporateVoucherRedemptions.to) {
      return [];
    }
    var from = this.paginationCorporateVoucherRedemptions.current_page - this.offset;
    if (from < 1) {
      from = 1;
    }
    var to = from + (this.offset * 2);
    if (to >= this.paginationCorporateVoucherRedemptions.last_page) {
      to = this.paginationCorporateVoucherRedemptions.last_page;
    }
    var pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    return pagesArray;
  },

    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    }
  },
  components: {
    CodeInput,
    FormWizard,
    Loading,
    TabContent,
    DatePicker,
    Multiselect
  },
  mounted (){
    this.getOutletByMerchant();
  },
  methods: {
    ...mapActions(["getVouchers","getVoucherRedemptions","getOutletsByUserId","verifyVoucherCode","redeemVoucher", "getCorporateVoucherRedemptions","updateVoucherExpiryDate"]),
    getParkingVouchersData(){
      let self = this;
      this.getVouchers({page: this.paginationParkingVouchers.current_page, from_date:this.start_date,to_date:this.end_date,
        status:this.transaction_status, voucherType : "PARKING", outletId: this.outlet.id})
          .then(function (vouchers) {
            self.parking_vouchers = vouchers.content;
            self.paginationParkingVouchers.current_page = vouchers.number;
            self.paginationParkingVouchers.total = vouchers.totalElements;
            self.paginationParkingVouchers.per_page = vouchers.numberOfElements;
            self.paginationParkingVouchers.from = vouchers.pageable.offset + 1 ;
            self.paginationParkingVouchers.to = vouchers.pageable.pageSize;
            self.paginationParkingVouchers.last_page = vouchers.totalPages;
          })
          .catch(function (error) {

          })
    },
    openVoucherExpiryModalModal(voucher, voucherType) {
      this.voucherExpiry.voucherId = voucher.id;
      this.voucherExpiry.voucherType = voucherType;
      this.$bvModal.show("update-voucher-expiry")
    },
    submitVoucherExpiry() {
      this.isLoading = true;

      let self = this;

      this.updateVoucherExpiryDate( this.voucherExpiry).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Voucher expiry updated", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });
        self.getParkingVouchersData()
      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    nextStep(e){
    },
    previousStep(e){
    },
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getVoucherRedemptionsData();
    },
    changeCorporateVoucherRedemptionPage: function (page) {
      this.paginationCorporateVoucherRedemptions.current_page = page;
      this.getCorporateVoucherRedemptionsData();
    },
    getCorporateVoucherRedemptionsData(){
      let self = this;

      let outlet_id = 0;

      if(this.outlet){
        outlet_id = this.outlet.id;
      }

      this.getCorporateVoucherRedemptions({outletId: outlet_id, startDate: this.start_date, endDate: this.end_date, page: this.paginationCorporateVoucherRedemptions.current_page, customerId: this.institutionId})
          .then(function (vouchers) {

            self.corporateVoucherRedemptions = vouchers.content;
            self.paginationCorporateVoucherRedemptions.current_page = vouchers.number;
            self.paginationCorporateVoucherRedemptions.total = vouchers.totalElements;
            self.paginationCorporateVoucherRedemptions.per_page = vouchers.numberOfElements;
            self.paginationCorporateVoucherRedemptions.from = vouchers.pageable.offset + 1 ;
            self.paginationCorporateVoucherRedemptions.to = vouchers.pageable.pageSize;
            self.paginationCorporateVoucherRedemptions.last_page = vouchers.totalPages;

          })
          .catch(function (error) {


          })
    },
    getVoucherRedemptionsData(){

      this.isLoading = true;

      let self = this;

      let outlet_id = 0;

      if(this.outlet){
        outlet_id = this.outlet.id;
      }

      this.getVoucherRedemptions({outletId: outlet_id, startDate: this.start_date, endDate: this.end_date, page: this.pagination.current_page})
          .then(function (vouchers) {

            self.isLoading = false;

            self.vouchers = vouchers.content;
            self.pagination.current_page = vouchers.number;
            self.pagination.total = vouchers.totalElements;
            self.pagination.per_page = vouchers.numberOfElements;
            self.pagination.from = vouchers.pageable.offset + 1 ;
            self.pagination.to = vouchers.pageable.pageSize;
            self.pagination.last_page = vouchers.totalPages;

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error);

            /*if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/app/sessions/signIn");
              }

            }*/
          })
    },

    getOutletByMerchant(){
      let self = this;

      this.isLoading = true;
      this.getOutletsByUserId({userId: localStorage.getItem("userId")})
          .then(function (outlets) {

            self.isLoading = false;

            self.outlets = outlets;

            if(self.outlets.length > 0){
              let parkingVoucherPermissions = self.outlets.filter(outlet => outlet.parkingVoucherAuthorization === "AUTHORIZED");
              if(parkingVoucherPermissions.length > 0) {
                self.canGenerateParkingVoucher = true
              }

              self.outlet = self.outlets[0];
              if(self.outlet) {
                self.getVoucherRedemptionsData();
                self.getCorporateVoucherRedemptionsData();
                self.getParkingVouchersData()
              }


            }
          })
          .catch(function (error) {

            self.isLoading = false;

            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    },
    submitVoucherCodeVerification(){

      let self = this;
      return new Promise((resolve, reject) => {
        self.$v.$touch();
        if (!self.$v.$invalid) {
          self.isLoading = true;

          self.verifyVoucherCode({voucherCode: this.redemption.redemptionCode})
              .then(function (response) {
                self.isLoading = false;

                if(response.statusCode == 200){
                  self.verificationCode = response.message;
                  resolve(true);
                }
                else{
                  reject({message:"Customer does not exist"});
                }

              })
              .catch(function (error) {

                self.isLoading = false;

                reject({});

              });
        } else {
          self.$bvToast.toast("Please enter voucher code to proceed", {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        }
      })
    },
    verifyVcode(){
      let self = this;
      return new Promise((resolve, reject) => {
        if(self.verificationCode == self.customerVerificationCode){
          self.voucherCodeVerified = true;
          resolve(true);
        }
        else{
          reject({});
        }
      });
    },
    onComplete(v) {
      this.customerVerificationCode = v;
    },

    onChange(v) {

    },

    validateBeforeSubmitRedemption(){
      let self = this;

        if(parseInt(this.redemption.amount) >= 1){
          return new Promise((resolve, reject) => {

            self.$v.$touch();
            if (!self.$v.$invalid) {
              self.isLoading = true;

              this.redemption.redeemedBy = localStorage.getItem("userId");

              self.redeemVoucher(self.redemption).then((response) => {
                self.isLoading = false;

                resolve(true);

                this.$bvModal.hide("redeem-voucher");

                self.$bvToast.toast("Voucher redemption success", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getVoucherRedemptionsData();

              }).catch(error => {
                self.isLoading = false;
                console.log(error);
                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              })
            }
          });
        }
        else{
          this.$bvToast.toast("Redemption amount should be greater than or equal to 1. ", {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        }
    },

  }
};
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#4AB5E6 !important;
}


.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -67px;
  color:#4AB5E6;
  font-weight: 600;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 20px;
  width: 20px;
  background-color: #4AB5E6;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:14px;
  left:15.5%;
  width: 69.5% !important;
  background-color: #4AB5E6 !important;
  height: 2px !important;
}

.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label{
  font-size: 14px;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #4AB5E6 !important;
  color:#000 !important;
  border: none;
  margin-top: 20px;
}
</style>

